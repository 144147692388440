<template>
   <v-row
   class="pt-0"
   >
    <v-col
      lg="12"
      class="pl-3 pr-3 pt-0"
    >
      <v-card
        class="OnHover pa-3"
      >
        <v-data-table
          :headers="headers"
          :items="tableData"
          :items-per-page="50"
          loading-text="Loading data...."
          :loading="loading"
          :options="dataTableOptions"
          dense
          class="centerheadings"
        >
          <template v-slot:item="{ item, select }">
            <tr
              @click="handleRowClick(item)"
            >
              <td>
                <v-icon v-if="item.rowIndex == selectedRow">
                  mdi-forward
                </v-icon>{{ item.sn }}
              </td>
              <td>
                {{ item.userName }}
              </td>
              <template v-for="yearmonthHeader in headers ">
                <template v-if="yearmonthHeader.isYearMonth">
                  <td :key="yearmonthHeader.value+item.sn">
                    <div :class="highlightTopTen(item.sn, yearmonthHeader.value)">
                      {{ formatData(item[yearmonthHeader.value]) }}
                    </div>
                  </td>
                </template>
              </template>
            </tr>
          </template>
        </v-data-table>
        <v-btn 
          color="primary"
          dark
          class="mb-2 pa-0"
          :before-generate="beforeGenerateExcel"
          :before-finish="beforeFinishExcel"
          @click="handleDownload"
        >
          Download <v-icon
            small
            class="mr-1"
            right
          >
            mdi-download
          </v-icon>
        </v-btn>      
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import Utils from '@/api/util'
  import moment from 'moment'
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  import { findMonthlyDataTable, findMonthlyTopTen } from '@/api/PouchDaoReport'
  import store from '@/store'
  import * as PizZip from 'pizzip'
  import * as docxtemplater from 'docxtemplater'
  import * as FileSaver from 'file-saver'

  const monthYearPattern = /^((20)\d{4}$)/

  export default {
    name: 'UsageDataTable',
    props: {
      dataCategory: {
        type: String,
        default: 'DOMESTIC DATA',
      },
      dataType: {
        type: String,
        default: 'CALL',
      },
      dataYearMonth: {
        type: Number,
      },
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('usage', {
        refreshRequired: 'refreshRequired',
        defaultTopTenMonth: 'defaultTopTenMonth',
      }),
      excelFileName () {
        if (this.dataCategory) {
          return this.dataCategory + ' Service Numbers'
        } else {
          return 'usageDataTable'
        }
      },
      excelTitle () {
        // console.info('excelTitle category ' + this.category, this.category + ' ' + this.dataCategory)
        return this.dataCategory + ' ' + this.dataType
      }
    },
    data () {
      return {
        selectedRow: -1,
        excelHeaderRow: {},
        excelMeta: [
          [
            {
              key: 'charset',
              value: 'utf-8'
            }
          ]
        ],
        dataTableOptions: {
          itemsPerPage: 30,
          // sortBy: ['201711'],
          // sortDesc: [true]
        },
        loading: false,
        headers: [
          {
            text: 'SN',
            align: 'left',
            sortable: false,
            value: 'sn',
          },
          { text: 'Jan', value: '201801' },
          { text: 'Feb', value: '201802' },
          { text: 'Mar', value: '201803' },
          { text: 'Apr', value: '201804' },
          { text: 'May', value: '201805' },
        ],
        tableData: [],
        topTenLookUpMap: new Map(),
        excelFileType: 'xlsx'
      }
    },
    watch: {
      dataType: {
        handler () {
          this.updateHeaders()
        },
      },
      dataYearMonth: {
        handler () {
          console.log('dataYearMonth changed ', this.dataYearMonth)
          this.sortColumn(this.dataYearMonth)
        },
      },
      defaultTopTenMonth: {
        handler () {
          console.log('defaultTopTenMonth changed', this.defaultTopTenMonth)
          this.sortColumn(this.dataYearMonth)
        },
      },
    },
    mounted () {
      console.log('mounted dataCategory ' + this.dataCategory + ' dataType ' + this.dataType)
      this.updateHeaders()
    },
    methods: {
      handleRowClick (value) {
        console.log('handleRowClick rowindex is ' + value.rowIndex)
        this.selectedRow = value.rowIndex
        store.dispatch('usage/updateSnFilter', { sn: value.sn })
      },
      sortColumn (columnName) {
        console.log('sortColumn called for ' + columnName)
        if (columnName) {
          this.dataTableOptions = {
            itemsPerPage: 30,
            sortBy: [columnName + ''],
            sortDesc: [true],
          }
        } else {
          this.dataTableOptions = {
            itemsPerPage: 30,
            sortBy: [],
            sortDesc: [true],
          }
        }
      },
      updateHeaders () {
        console.log('updateHeaders for category ' + this.dataCategory + ' dataType ' + this.dataType)
        this.loading = true

        // try to update the headers...
        // retrieve list from database
        // look through the properties of an object...
        // pattern is 201801 starts with 20 and 4 digits
        // const monthYearPattern = /^((20)\d{4}$)/
        let dataHeaders = []
        findMonthlyDataTable(this.dataCategory, this.dataType).then(result => {
          console.log('updateHeaders return ' + result.length)
          if (!result[0]) {
            console.error('updateHeaders category ' + this.dataCategory + ' dataType ' + this.dataType + ' returns 0 result not processing headers')
            return // first row not ready
          }
          let firstRow = result[0].doc
          dataHeaders.push({ text: 'Mobile', value: 'sn', isYearMonth: false })
          dataHeaders.push({ text: 'User Name', value: 'userName', isYearMonth: false })
          let excelHeader = {
            Mobile: 'sn',
            'User Name': 'userName',
          }
          Object.keys(firstRow).forEach(key => {
            const matchResult = key.match(monthYearPattern)
            if (matchResult) {
              const monthYear = moment(key + '01', 'YYYYMMDD')
              const monthLabel = monthYear.format('MMM YY')
              // console.log(`${key} : ${monthLabel}`)
              dataHeaders.push({ text: monthLabel, value: key, isYearMonth: true })
              excelHeader[monthLabel] = key
            }
          })
          let dataRows = Promise.resolve(result)
          let topTenTenPromises = []
          // for each
          dataHeaders.forEach(yearMonthColumn => {
            // console.log('findMonthlyTopTen for ' + this.dataCategory + ' ' + this.dataCategory + ' ' + yearMonthColumn.value)
            if (yearMonthColumn.value.match(monthYearPattern)) {
              let findTopTen = findMonthlyTopTen(this.dataCategory, this.dataType, yearMonthColumn.value).then(result => {
                // TODO store the topten in vuex so this method can be refactored...
                // returns a list of sn and value in desending order {sn: 123123, qty: 223}
                // we need to know the position ie first of top ten, second of top ten, third ....
                // the matching is by sn,
                let topTenMap = new Map()
                let i = 1
                result.forEach(topTenItem => {
                  // console.log('dataHeaders adding key ' + yearMonthColumn.value + topTenItem.sn + ' value ' + i)
                  topTenMap.set(yearMonthColumn.value + topTenItem.sn, i)
                  i++
                })
                // console.log('findMonthlyTopTen for ' + this.category + ' ' + this.dataCategory + ' ' + yearMonthColumn.value + ' completed  descending order')
                // console.log(result)
                return topTenMap
              })
              topTenTenPromises.push(findTopTen)
            }
          })
          Promise.all([dataRows, Promise.all(topTenTenPromises)]).then(result => {
            console.log('findMonthlyTopTenPromises completed for ' + this.dataCategory + ' ' + this.dataType)
            let topTenTenPromises = result[1]
            let topTenMapContainer = new Map()
            topTenTenPromises.forEach(topTenMap => {
              topTenMap.forEach((value, key, map) => {
                // console.log('adding topTenContainer key ' + key + ' value ' + value)
                topTenMapContainer.set(key, value)
              })
            })
            this.topTenLookUpMap = topTenMapContainer
            this.headers = dataHeaders
            this.excelHeaderRow = excelHeader
            this.resetData(result[0])
          })

          // console.log('findMonthlyDataTable header values are ')
          // console.log(dataHeaders)
        })
      },
      resetData (result) {
        console.log('resetData with records ' + result.length)
        let dataRows = []
        let rowIndex = 0
        result.forEach(row => {
          const data = row.doc
          data.rowIndex = rowIndex++
          dataRows.push(data)
        })
        this.tableData = dataRows
        this.loading = false
        store.dispatch('usage/updateDefaultTopTenMonth', { category: this.dataCategory, dataCategory: this.dataType })
      },
      getColor (item) {
        // if (calories > 400) return 'red'
        // else if (calories > 200) return 'orange'
        // else return 'green'
        //  console.log('getColor ' + item)
        return 'green'
      },
      formatData (qty) {
        if (this.dataType === 'USAGE') {
          return Utils.readableBytes(qty)
        } else if (this.dataType === 'DURATION') {
          return Utils.convertMinutesToDhms(qty)
        } else if (this.dataType === 'TOTAL') {
          return Utils.formatCurrency(qty)
        } else if (this.dataType === 'EXCESS') {
          return Utils.formatCurrency(qty)
        } else {
          return qty
        }
      },
      highlightTopTen (sn, yearMonth) {
        // console.log('highlightTopTen ' + sn + ' yearMonth ' + yearMonth)
        let topTenRank = this.topTenLookUpMap.get(yearMonth + sn + '')
        if (topTenRank) {
          return 'topTen' + topTenRank
        } else {
          return 'topTenOther'
        }
        // console.log('highlightTopTen ' + sn + ' yearMonth ' + yearMonth + ' rank is ' + topTenRank)
      },
      beforeGenerateExcel () {
        store.dispatch('app/setProgress', true)
      },
      beforeFinishExcel () {
        store.dispatch('app/setProgress', false)
      },
      exportToExcel () {
        console.log('excelHeader', this.excelHeaderRow)
        console.log('first row of table data is ', this.tableData[0])
        return this.tableData
      },
      generateReport () {
        const publicPath = '.' // process.env.PATH
        let sampleDocPath = publicPath + '/template/blitz-demo-loop-table2.docx'
        console.log('generateReport sampleDocPath is ' + sampleDocPath)
        // console.log('table data is ', this.tableData)
        // data is [ 201901:2123, 201901:1123123,...]
        // need to translate back to month1, month2...
        // take the first row and set the month value
        let dataHeaders = []
        Object.keys(this.tableData[0]).forEach(key => {
          const matchResult = key.match(monthYearPattern)
          if (matchResult) {
            // const monthYear = moment(key + '01', 'YYYYMMDD')
            // const monthLabel = monthYear.format('MMM YY')
            // console.log(`${key} : ${monthLabel}`)
            dataHeaders.push(key)
          }
        })
        let i = 1
        let monthColumns = []
        dataHeaders.sort().forEach(key => {
          monthColumns.push({ month: 'month' + i++, monthYear: key })
        })
        // noq built the data
        let snNumbers = []
        let max = 12
        let l = 0
        let snHeader = {
          sn: 'Service Number',
          user_name: 'User Name',
        }
        let excelHeader = {
          'Service Number': 'sn',
          'User Name': 'userName',
        }
        // populate the header row
        monthColumns.forEach(monthColumn => {
          snHeader[monthColumn.month] = moment(monthColumn.monthYear + '01', 'YYYYMMDD').format('MMM YY')
          excelHeader[moment(monthColumn.monthYear + '01', 'YYYYMMDD').format('MMM YY')] = monthColumn.monthYear
        })
        snNumbers.push(snHeader)
        this.tableData.forEach(row => { // populate sn amount for each year
          let snRow = { sn: row.sn, user_name: row.userName }
          monthColumns.forEach(header => {
            snRow[header.month] = Utils.formatCurrency(row[header.monthYear])
            if (l < max) {
              let value = row[header.monthYear]
              // console.log('value for month ' + header.month + ' value ' + value)
              l++
              console.log('row', row)
            }
          })
          snNumbers.push(snRow)
        })
        console.log('excel headers ', excelHeader)
        console.log('sn header ', snNumbers[0])
        console.log('sn roq ', snNumbers[1])

        // ok now try creating excel row objects...
        let excelList = []
        snNumbers.forEach(snRow => {
          const excelRow = {

          }
        });
      },
      handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = []
          const filterVal = []

          this.headers.forEach((item, index) => {
            tHeader.push(item.text)
            filterVal.push(item.value)
          });
                    
          const list = this.tableData
          const data = list.map( v => filterVal.map(j => v[j] ))  
          excel.export_json_to_excel({
            header: tHeader,
            sheetTitle: 'Usage',
            data,
            filename: this.excelFileName,
            autoWidth: this.autoWidth,
            bookType: this.excelFileType
          })
        })
      }
    },
  }
  
</script>
<style>
  .topTen1 {
    background-color: #F6B41A !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen2 {
    background-color: #f89307 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen3 {
    background-color: #F66021 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen4 {
    background-color: #E54B3C !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen5 {
    background-color: #2790BC!important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen6 {
    background-color: #00ADC3 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen7 {
    background-color: #009687 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen8 {
    background-color: #429F46 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen9 {
    background-color: #7AB040 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
  }
  .topTen10 {
    background-color: #BEC932 !important;
    border-color: #FFFFFF !important;
    border-radius: 5px;
    color: white;
    text-align: left;
    padding-left:5px
   }
  .topTenOther {
    text-align: left;
    padding-left:5px
  }
</style>
