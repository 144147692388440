<template>
  <v-container
    id="noUsageBillTotal"
    fluid
    tag="section"
    class="pt-0 pb-0"
  >
    <v-row
      class="pt-0 pb-0"
    >
      <v-col
        cols="12"
        sm="6"
        lg="8"
        class="pt-0"
      >
          <usage12-months-line-chart 
            data-category="NOUSAGE"
            data-type="TOTAL"
            :data-year-month="dataYearMonth" 
          />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="pt-0"
      >
          <usage-top-ten-pie-chart 
            data-category="NOUSAGE"
            data-type="TOTAL"  
            :data-year-month="dataYearMonth" 
          />
      </v-col>
    </v-row>
    <v-row
      class="pt-0 pb-0 mt-5"
    >
      <v-col
        cols="12"
        sm="12"
        lg="12"
        class="pt-0 mt-0"
      >
        <usage-data-table
          data-category="NOUSAGE"
          data-type="TOTAL"
          :data-year-month="dataYearMonth"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Usage12MonthsLineChart from '@/views/blitz/components/apex/UsageBillTotal12MonthsLineChart'
  import UsageTopTenPieChart from '@/views/blitz/components/apex/UsageBillTotalTopTenPieChart'
  import UsageDataTable from '@/views/blitz/components/UsageDataTable'

  export default {
    name: 'NoUsageBill',
    components: {
      Usage12MonthsLineChart,
      UsageTopTenPieChart,
      UsageDataTable,
    },
    props: {
      dataType: {
        type: String,
        default: 'TOTAL',
      },
      dataYearMonth: {
        type: Number,
      },

    },
    computed: {
      // Following will only add getters..
      // How to add setter ???

      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
    },
    data () {
      return {
        refreshData: true,
        tabs: 0,
        dataCategory: 'TOTAL',
      }
    },
    mounted () {
      if (this.refreshData) {
        this.refreshData = false
      }
    },
    methods: {
      switchCategory (category) {
        console.log('switching category to ' + category) // category to CALL, DURATION, DATA, EXCESS
        this.dataCategory = category
      },
    },
  }

</script>

<style scoped>

</style>
